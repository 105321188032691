export const section = {
  abacus: {
    aiEngineName: "abacus",
    entityId: "506035",
    entityUuid: "98cbd2d9-35e4-49bd-a3c2-e02f9fc9003d",
    name: "Abacus",
    routeType: "Section",
    urlAlias: "/abacus",
  },
  announcements: {
    entityId: "322430",
    entityUuid: "d9ccc819-964c-46e6-91f4-1e2593372396",
  },
  asia: {
    aiEngineName: "asia",
    entityId: "3",
    name: "Asia",
  },
  betterLife: {
    aiEngineName: "better-life",
    entityId: "501963",
    entityUuid: "f0b06cdd-ec55-441a-bb2f-c112c567cd06",
    name: "Better life",
    routeType: "Section",
    urlAlias: "/better-life",
  },
  business: {
    aiEngineName: "business",
    entityId: "92",
    entityUuid: "718a073e-4006-4037-871e-6f6734324b4b",
    name: "Business",
    routeType: "Section",
    urlAlias: "/business",
  },
  china: {
    aiEngineName: "china",
    entityId: "4",
    name: "China",
  },
  chinaTechCity: {
    aiEngineName: "china-tech-city",
    entityId: "501873",
  },
  comment: {
    aiEngineName: "comment",
    entityId: "93",
    entityUuid: "75878f6f-55b9-4675-8384-7dfcf67cd6b1",
    harrysView: {
      entityId: "76670",
      entityUuid: "caee4614-a67e-4710-9331-2e41261e636e",
      name: "Harry's View",
      routeType: "Section",
      urlAlias: "/comment/harrys-view",
    },
    letters: {
      entityId: "22",
      entityUuid: "a975107c-d167-41b3-8f74-7b8be3e935b0",
      name: "Letters",
      routeType: "Section",
      urlAlias: "/comment/letters",
    },
    name: "Comment",
    opinion: {
      entityId: "17",
      entityUuid: "2301d056-f8fd-4130-8768-1f1b1a40261f",
      name: "Opinion",
      routeType: "Section",
      urlAlias: "/comment/insight-opinion",
    },
    routeType: "Section",
    urlAlias: "/comment",
  },
  cooking: {
    aiEngineName: "cooking",
    entityId: "502060",
    entityUuid: "36ed00d8-5455-4bf8-be71-5b841beefcca",
    name: "Cooking",
    routeType: "Section",
    urlAlias: "/cooking",
  },
  coronavirus: {
    aiEngineName: "coronavirus",
    entityId: "505323",
    entityUuid: "ab187950-76a6-419b-b548-a74cd4286f5f",
    name: "Coronavirus",
    routeType: "Section",
    urlAlias: "/coronavirus",
  },
  culture: {
    aiEngineName: "culture",
    entityId: "322296",
  },
  economy: {
    aiEngineName: "economy",
    entityId: "328461",
    entityUuid: "4aa8843f-16e2-4705-99d6-b53f22f6f75a",
    name: "Economy",
    routeType: "Section",
    urlAlias: "/economy",
  },
  explained: {
    aiEngineName: "explained",
    entityId: "328486",
    entityUuid: "68445a51-2d2b-48dd-ba4b-1154bb1ffbec",
    name: "Explained",
    routeType: "Section",
    urlAlias: "/explained",
  },
  goldthread: {
    aiEngineName: "goldthread",
    entityId: "327251",
  },
  hongKong: {
    aiEngineName: "hong-kong",
    entityId: "2",
    name: "Hong Kong",
  },
  infographics: {
    aiEngineName: "infographics",
    entityId: "309585",
    entityUuid: "50c20b8d-5141-42a9-a2ed-19e8701da284",
    name: "Infographics",
    routeType: "Section",
    urlAlias: "/infographic",
  },
  kPop: {
    aiEngineName: "k-pop",
    entityId: "507629",
    entityUuid: "4cd69b05-aa7d-406b-ba9e-9c645820bed7",
    name: "K-Pop",
    routeType: "Section",
    urlAlias: "/k-pop",
  },
  knowledge: {
    aiEngineName: "knowledge",
    entityId: "507449",
    entityUuid: "e490b26d-0451-46fd-905a-5fbb45c0590a",
    name: "Knowledge",
    routeType: "Section",
    urlAlias: "/knowledge",
  },
  lawAndCrime: {
    entityId: "318211",
    entityUuid: "8af52903-fd3d-4916-a7e7-605ee9b302b0",
    name: "Law and Crime",
    routeType: "Section",
    urlAlias: "/news/hong-kong/law-and-crime",
  },
  lifestyle: {
    aiEngineName: "lifestyle",
    artsAndCulture: {
      entityId: "23",
      entityUuid: "11ab5d0c-7206-4ca7-a828-356b70e3ac01",
      name: "Arts & Culture",
      routeType: "Section",
      urlAlias: "/lifestyle/arts-culture",
    },
    entertainment: {
      entityId: "328490",
      entityUuid: "f1bd667f-3a0f-45b3-8c5c-5a938e5e48cf",
      name: "Entertainment",
      routeType: "Section",
      urlAlias: "/lifestyle/entertainment",
    },
    entityId: "94",
    entityUuid: "6354b86c-8bb8-47ef-b370-edf637245976",
    familyAndRelationships: {
      entityId: "29",
      entityUuid: "15a346ea-db3d-4200-a959-22327abeb0e8",
      name: "Family & Relationships",
      routeType: "Section",
      urlAlias: "/lifestyle/family-relationships",
    },
    fashionAndBeauty: {
      entityId: "28",
      entityUuid: "41c84846-843b-4ce5-86d1-2ca487e1cbda",
      name: "Fashion & Beauty",
      routeType: "Section",
      urlAlias: "/lifestyle/fashion-beauty",
    },
    foodAndDrink: {
      entityId: "30",
      entityUuid: "ecc2bdff-77e7-47e2-9345-c4e93bff7088",
      name: "Food & Drink",
      routeType: "Section",
      urlAlias: "/lifestyle/food-drink",
    },
    healthAndWellness: {
      entityId: "24",
      entityUuid: "9a663eba-7673-41bd-9bfa-292cfca7bbe2",
      name: "Health & Wellness",
      routeType: "Section",
      urlAlias: "/lifestyle/health-wellness",
    },
    name: "Lifestyle",
    routeType: "Section",
    travelAndLeisure: {
      entityId: "37",
      entityUuid: "20a2879a-cde5-486e-aabe-4b378c8eea39",
      name: "Travel & Leisure",
      routeType: "Section",
      urlAlias: "/lifestyle/travel-leisure",
    },
    urlAlias: "/lifestyle",
  },
  magazine: {
    entityId: "99",
    entityUuid: "65cd885c-0a6c-42c5-881e-0dc9fcdca455",
    name: "Magazines",
  },
  martialArts: {
    aiEngineName: "martial-arts",
    entityId: "501166",
  },
  news: {
    aiEngineName: "news",
    asia: {
      entityId: "3",
      entityUuid: "3d582821-f766-41ed-94d1-d4b316a10602",
      name: "Asia",
      routeType: "Section",
      urlAlias: "/news/asia",
    },
    china: {
      entityId: "4",
      entityUuid: "08925cfe-01ce-4932-b9e2-be5b255efe63",
      name: "China",
      routeType: "Section",
      urlAlias: "/news/china",
    },
    entityId: "91",
    entityUuid: "86e1c519-948e-4460-adc3-1dfafefa60da",
    hongKong: {
      entityId: "2",
      entityUuid: "465e2368-b5d9-4ab9-a471-e7ff921e6389",
      name: "Hong Kong",
      routeType: "Section",
      urlAlias: "/news/hong-kong",
    },
    name: "News",
    peopleAndCulture: {
      entityId: "318202",
      entityUuid: "c3524c79-f0d5-48fd-b834-36a56894c672",
      name: "People & Culture",
      routeType: "Section",
      urlAlias: "/news/people-culture",
    },
    routeType: "Section",
    urlAlias: "/news",
    world: {
      entityId: "5",
      entityUuid: "fc2e7dc2-9791-410a-906c-28ae8a6cf9db",
      name: "World",
      routeType: "Section",
      urlAlias: "/news/world",
    },
  },
  olympic: {
    aiEngineName: "paris-olympics",
    entityId: "518881",
    entityUuid: "6418d821-6d87-4135-9059-f76017b37a6b",
    name: "Paris Olympics 2024",
    routeType: "Section",
    urlAlias: "/paris-olympics",
  },
  peopleCulture: {
    aiEngineName: "people-culture",
    entityId: "318202",
    name: "People & Culture",
  },
  photo: {
    entityId: "97",
    entityUuid: "38b04fac-790c-4dcc-a214-23c6f592ae4a",
    harrysView: {
      entityId: "324599",
      entityUuid: "ee4a523c-0f17-4109-b503-8a907f05537b",
      name: "Harry's View",
      routeType: "Section",
      urlAlias: "/photos/harrys-view",
    },
    name: "Pictures",
    routeType: "Section",
    urlAlias: "/photos",
  },
  podcasts: {
    aiEngineName: "podcasts",
    entityId: "328485",
    entityUuid: "84508dfa-40c3-4571-bc32-da1db88f67df",
    name: "Podcasts",
    routeType: "Section",
    urlAlias: "/podcasts",
  },
  postMagazine: {
    aiEngineName: "post-magazine",
    artsAndMusic: {
      entityId: "321713",
      entityUuid: "64b859a8-cd16-4cf5-b6f3-ef77077c152a",
      name: "Arts & Music",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/arts-music",
    },
    books: {
      entityId: "321720",
      entityUuid: "34611753-4ea8-4c58-97ca-0099c41f66d1",
      name: "Books",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/books",
    },
    designAndInteriors: {
      entityId: "322659",
      entityUuid: "70b76c33-b04a-4126-894b-71c93092a288",
      name: "Design & Interiors",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/design-interiors",
    },
    entityId: "71",
    entityUuid: "6905550f-cf81-405a-8a19-6ffd324a9bb0",
    fashion: {
      entityId: "321719",
      entityUuid: "9e2b2483-6738-42a7-851d-9593624f00c6",
      name: "Fashion",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/fashion",
    },
    foodAndDrink: {
      entityId: "321716",
      entityUuid: "44f3d548-abb6-482c-878b-068e06f5bf1a",
      name: "Food & Drink",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/food-drink",
    },
    longReads: {
      entityId: "322658",
      entityUuid: "07cbf524-3aa6-4201-b3f3-28c276b92cd3",
      name: "Long Reads",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/long-reads",
    },
    name: "Post Magazine",
    routeType: "Section",
    shortReads: {
      entityId: "323244",
      entityUuid: "c9080138-966e-498b-a9bf-c21303061b04",
      name: "Short Reads",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/short-reads",
    },
    travel: {
      entityId: "321721",
      entityUuid: "13262829-8186-44aa-afc8-71f80fffec36",
      name: "Travel",
      routeType: "Section",
      urlAlias: "/magazines/post-magazine/travel",
    },
    urlAlias: "/magazines/post-magazine",
  },
  posties: {
    entityId: "515415",
    entityUuid: "2a786249-ee3e-4fda-9991-0d757340f9a7",
    name: "Posties",
    read: {
      entityUuid: "4f55e50d-8d3e-438b-89a0-98e39cb9b66b",
    },
    routeType: "Section",
    urlAlias: "/posties",
  },
  property: {
    aiEngineName: "property",
    entityId: "96",
  },
  racing: {
    entityId: "39",
  },
  rugby: {
    aiEngineName: "rugby",
    entityId: "310895",
  },
  scmpFilms: {
    // Treat drupal video sections as sections since content service does not include video section
    entityId: "329005",
    entityUuid: "3fff713e-a6ed-464b-989a-014ddad7952d",
    name: "SCMP Films",
    routeType: "Section",
    urlAlias: "/scmp-films",
  },
  scmpOriginals: {
    entityId: "315555",
  },
  scmpPrBlog: {
    entityId: "512548",
    entityUuid: "95938fd2-5c1b-41a8-bccb-c4550723ed25",
  },
  sport: {
    aiEngineName: "sport",
    entityId: "95",
    entityUuid: "078620c2-f6d7-4b1f-872e-7b4083c87e38",
    name: "Sport",
    outdoor: {
      aiEngineName: "outdoor",
      entityId: "324931",
    },
    racing: {
      entityId: "39",
    },
    routeType: "Section",
    urlAlias: "/sport",
  },
  style: {
    aiEngineName: "style",
    beauty: {
      entityId: "516298",
      name: "Beauty",
      urlAlias: "/magazines/style/beauty",
    },
    celebrity: {
      entityId: "322898",
      name: "Celebrity",
      urlAlias: "/magazines/style/celebrity",
    },
    entertainment: {
      entityId: "515034",
      urlAlias: "/lifestyle/entertainment",
    },
    entityId: "72",
    entityUuid: "9891fb45-50fa-4d5c-9210-f8a2597dec09",
    fashion: {
      entityId: "516294",
      name: "Fashion",
      urlAlias: "/magazines/style/fashion",
    },
    leisure: {
      entityId: "322899",
      name: "Leisure",
      urlAlias: "/magazines/style/leisure",
    },
    lifestyle: {
      entityId: "516305",
      name: "Lifestyle",
      urlAlias: "/magazines/style/lifestyle",
    },
    luxury: {
      entityId: "322897",
      name: "Luxury",
      urlAlias: "/magazines/style/luxury",
    },
    name: "Style",
    newsTrends: {
      entityId: "322896",
      name: "News & Trends",
      urlAlias: "/magazines/style/news-trends",
    },
    people: {
      entityId: "516301",
      name: "People",
      urlAlias: "/magazines/style/people",
    },
    routeType: "Section",
    techDesign: {
      entityId: "322901",
      name: "Tech & Design",
      urlAlias: "/magazines/style/tech-design",
    },
    urlAlias: "/magazines/style",
    watches: {
      entityId: "318256",
      urlAlias: "/magazines/style/watches",
    },
    watchesJewellery: {
      entityId: "322900",
      urlAlias: "/magazines/style/watches-jewellery",
    },
    wellBeing: {
      entityId: "501517",
      name: "Well-Being",
      urlAlias: "/magazines/style/well-being",
    },
  },
  talkingPost: {
    aiEngineName: "talking-post",
    entityId: "511121",
    entityUuid: "8548b1fa-b79c-4124-b2f4-63f3ca3affd4",
    name: "Talking Post",
    routeType: "Section",
    urlAlias: "/talking-post",
  },
  tech: {
    aiEngineName: "tech",
    entityId: "36",
    entityUuid: "068377d8-31c9-4a54-bf7f-47b4892b5308",
    name: "Tech",
    routeType: "Section",
    urlAlias: "/tech",
  },
  thisWeekInAsia: {
    aiEngineName: "twia",
    economics: {
      entityId: "323048",
      entityUuid: "bf62d0af-fda6-467e-a572-25fea3a60255",
      name: "Economics",
      routeType: "Section",
      urlAlias: "/week-asia/economics",
    },
    entityId: "323045",
    entityUuid: "94de9d0d-5695-4d44-96d4-3d3e0f2cd73d",
    explained: {
      entityId: "328489",
      entityUuid: "dda01aec-24be-453b-ae3f-5a59da25b8b3",
      name: "Explained",
      routeType: "Section",
      urlAlias: "/week-asia/explained",
    },
    healthEnvironment: {
      entityId: "328487",
      entityUuid: "5f4f2fbd-96b3-4f9c-ad90-a6119cb7cae6",
      name: "Health & Environment",
      routeType: "Section",
      urlAlias: "/week-asia/health-environment",
    },
    lifestyleAndCulture: {
      entityId: "328488",
      entityUuid: "dd8eb76a-1ae9-4686-9226-8cd0195b13d8",
      name: "Lifestyle & Culture",
      routeType: "Section",
      urlAlias: "/week-asia/lifestyle-culture",
    },
    name: "This Week in Asia",
    opinion: {
      entityId: "323050",
      entityUuid: "a9e9e155-f6cb-4c50-9f01-5df69e2594b9",
      name: "Opinion",
      routeType: "Section",
      urlAlias: "/week-asia/opinion",
    },
    people: {
      entityId: "323052",
      entityUuid: "210b727d-9d62-47b6-8b33-3ce418383ead",
      name: "People",
      routeType: "Section",
      urlAlias: "/week-asia/people",
    },
    politics: {
      entityId: "323046",
      entityUuid: "abadb127-eda3-4fc4-a8b4-91a6a2b001f5",
      name: "Politics",
      routeType: "Section",
      urlAlias: "/week-asia/politics",
    },
    routeType: "Section",
    urlAlias: "/week-asia",
  },
  video: {
    entityId: "98",
    entityUuid: "5196ffc0-4820-4956-9def-e60eed26ee68",
    name: "Video",
    podcasts: {
      entityId: "515586",
      entityUuid: "b745d713-85a9-46f5-8408-3df710884d5d",
      name: "Podcasts",
      urlAlias: "/video/podcasts",
    },
    posties: {
      entityId: "515863",
      entityUuid: "73954174-2ab3-42b4-b6cc-e60e4a49e56c",
      name: "Posties",
      urlAlias: "/video/posties",
    },
    routeType: "Section",
    urlAlias: "/video",
    yp: {
      entityId: "503705",
      entityUuid: "6158f4fe-20d4-4319-b685-f4b59fbb7e08",
      name: "Young Post",
      urlAlias: "/video/yp",
    },
  },
  weather: {
    aiEngineName: "weather",
    entityId: "579",
  },
  world: {
    aiEngineName: "world",
    entityId: "5",
    name: "World",
  },
  yp: {
    aiEngineName: "yp",
    entityId: "503603",
    entityUuid: "c53d30d7-9375-4516-869f-8e62e130b2bd",
    name: "YP",
  },
} as const;
