import { useSyncedRef } from "@react-hookz/web";
import pick from "lodash/pick";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { useRouter } from "next/router";
import qs from "qs";
import type { CSSProperties, ReactNode } from "react";
import { forwardRef, useEffect } from "react";

import { tracking } from "scmp-app/data";
import { isEnabledClientRoute } from "scmp-app/lib/router";

import { useBaseLinkContext } from "./context";
import { StyledAnchor } from "./styles";

type ExtraAnchorProps = {
  name?: string;
};

export type Props = {
  anchorProps?: React.AnchorHTMLAttributes<HTMLAnchorElement> & ExtraAnchorProps;
  as?: string;
  children?: ReactNode;
  className?: string;
  hash?: string;
  isAnchor?: boolean;
  onNavigate?: () => void;
  pathname: string;
  query?: ExternalQuery & InternalQuery;
  style?: CSSProperties;
  target?: string;
} & Omit<LinkProps, "href" | "as" | "passHref">;

// InternalQuery is query string used internally and should not be shown in the href
type InternalQuery = Record<string, string | undefined>;

// ExternalQuery is query string used externally and should shown in the href
// Example Usage: GTM variables: `Query String - House Ad`
type ExternalQuery = Partial<{
  CMCampaignID: string;
  UUID: string; // eslint-disable-line @typescript-eslint/naming-convention
  campaign: string;
  display: string;
  module: string;
  origin: string;
  pgtype: string;
}>;
const externalQueryTypeTemplate = {
  campaign: "",
  display: "",
  module: tracking.module.Inline,
  origin: "",
  pgtype: tracking.pageType.Article,
  utm_source: "",
};
const validExternalQueryKeys = Object.keys(externalQueryTypeTemplate);

export const BaseLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      anchorProps,
      children,
      className,
      hash,
      isAnchor,
      onClick,
      onNavigate,
      pathname,
      query,
      style,
      target,
      ...props
    },
    reference,
  ) => {
    const { customQueryParameters } = useBaseLinkContext();

    const customizedTarget = target ?? anchorProps?.target ?? "";

    const renderAnchor = (href?: string) => (
      <StyledAnchor
        {...anchorProps}
        className={className}
        href={href}
        ref={reference}
        style={style}
        target={customizedTarget}
        onClick={onClick}
      >
        {children}
      </StyledAnchor>
    );

    const mergedQuery = { ...customQueryParameters, ...query };

    // Need to keep the external query string in the display url for GTM to pick up the values
    const externalQueryString = qs.stringify(pick(mergedQuery, validExternalQueryKeys), {
      addQueryPrefix: true,
    });
    const asUrl = `${props.as ?? pathname}${externalQueryString}${hash ?? ""}`;

    const { events } = useRouter();
    const latestOnNavigate = useSyncedRef(onNavigate);
    useEffect(() => {
      const onRouteChangeComplete = () => {
        latestOnNavigate?.current?.();
      };
      events.on("routeChangeComplete", onRouteChangeComplete);

      return () => {
        events.off("routeChangeComplete", onRouteChangeComplete);
      };
    }, [events, latestOnNavigate]);

    if (!isEnabledClientRoute(pathname) || isAnchor) {
      return renderAnchor(asUrl);
    }

    return (
      <Link
        {...props}
        {...anchorProps}
        as={asUrl}
        className={className}
        href={{ hash, pathname, query: mergedQuery }}
        passHref={true}
        ref={reference}
        style={style}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  },
);

BaseLink.displayName = "BaseLink";
