export enum Edition {
  Asia = "asia",
  HongKong = "hk",
  International = "int",
}

export const editions = {
  [Edition.International]: { href: "/", label: "International" },
  [Edition.HongKong]: { href: "/hk", label: "Hong Kong" },
  [Edition.Asia]: { href: "/asia", label: "Asia" },
} as const;

export const editionOptions = [Edition.International, Edition.HongKong, Edition.Asia].map(
  edition => ({ value: edition, ...editions[edition] }),
);

export const headerEditionOptions = [Edition.HongKong, Edition.International, Edition.Asia].map(
  edition => ({ value: edition, ...editions[edition] }),
);

export const editionMetaDescription = {
  [Edition.International]:
    "News, opinions, infographics and videos on China issues: geopolitics, diplomacy, economy, technology and lifestyle.",
  [Edition.HongKong]:
    "News, opinions, infographics and videos on HK and China issues: geopolitics, economy, law & crime, weather and lifestyle.",
  [Edition.Asia]:
    "News, opinions, infographics and videos on Asia news in China, Hong Kong, Singapore, Malaysia, India, Japan, Korea, Australia and more.",
} as const;
